import React from "react";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import TrackVisibility from 'react-on-screen';
import ProjectCard from "./ProjectCard";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import projImg4 from "../assets/img/project-img4.png";
import projImg5 from "../assets/img/project-img5.png";
import colorSharp2 from "../assets/img/color-sharp2.png";

interface Project {
  title: string;
  description: string;
  imgUrl: string;
  githubLink: string;
  demoLink: string;
}

export const Projects: React.FC = () => {

  const projects: Project[] = [
    {
      title: "Blogs",
      description: "React\n Redux\n RESTful API\n Node.js\n Express\n MongoDB\n EC2\n JWT\n Caddy",
      imgUrl: projImg3,
      githubLink: "https://github.com/AudreyWu6/Blog-Website",
      demoLink: "https://66e8ed13ed55bb7833c1b204--rainbow-halva-10ef8d.netlify.app/",
    },
    {
      title: "Personal Website",
      description: "TypeScript\n React\n Animated CSS\n Express\n  Nodemailer\n Framer Motion\n CICD ",
      imgUrl: projImg4,
      githubLink: "https://github.com/AudreyWu6/personal-portfolio",
      demoLink: "https://audrey-wu-123.me/",
    },
    {
      title: "Online Shopping Website",
      description: "Next.js\n Tailwind CSS\n Supabase\n SEO",
      imgUrl: projImg5,
      githubLink: "https://github.com/AudreyWu6/",
      demoLink: "#",
    },
    {
      title: "Discussion Platform",
      description: "AJAX\n Vanilla JS\n Bootstrap\n RESTful API",
      imgUrl: projImg1,
      githubLink: "https://github.com/AudreyWu6/online-discussion-platform",
      demoLink: "https://discussion-platform-demo.com",
    },
    {
      title: "Slides Presentation",
      description: "React\n Material UI\n Express\n Postman \n Github",
      imgUrl: projImg2,
      githubLink: "https://github.com/AudreyWu6/slides-app",
      demoLink: "https://slides-presentation-demo.com",
    },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <h2>Projects</h2>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">ALL</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Web</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Mobile</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => (
                            <ProjectCard
                              key={index}
                              title={project.title}
                              description={project.description}
                              imgUrl={project.imgUrl}
                              githubLink={project.githubLink}
                              demoLink={project.demoLink}
                            />
                          ))
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                    <Row>
                        {
                          projects.map((project, index) => (
                            <ProjectCard
                              key={index}
                              title={project.title}
                              description={project.description}
                              imgUrl={project.imgUrl}
                              githubLink={project.githubLink}
                              demoLink={project.demoLink}
                            />
                          ))
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <p>In Progress...</p>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2} alt="Background" />
    </section>
  )
}